import "./App.css";

const Socials = () => {
	return (
		<>
			<a
				href="https://wa.me/37744363014"
				className="text-white text-xl h-content"
			>
				<img
					className="md:w-6 w-8 mt-5 md:mr-0 mr-5"
					src={require("./assets/wa.png")}
					alt="fb"
				/>
			</a>
			<a
				href="https://www.facebook.com/p/Mik-Adventures-100064132351604/"
				className="text-white text-xl h-content"
			>
				<img
					className="md:w-6 w-8 mt-5 md:mr-0 mr-5"
					src={require("./assets/fb.png")}
					alt="fb"
				/>
			</a>
			<a
				href="https://www.instagram.com/mik_adventures"
				className="text-white text-xl h-content"
			>
				<img
					className="md:w-6 w-8 mt-5 md:mr-0 mr-5"
					src={require("./assets/insta.png")}
					alt="fb"
				/>
			</a>
			<a
				href="https://www.tiktok.com/@mik_adventures"
				className="text-white text-xl h-content"
			>
				<img
					className="md:w-6 w-8 mt-5 md:mr-0 mr-8"
					src={require("./assets/tt.png")}
					alt="fb"
				/>
			</a>
		</>
	);
};

function App() {
	return (
		<div className="relative flex flex-1 max-h-dvh h-dvh bg-slate-950">
			<img
				src={require("./assets/bg.jpg")}
				className="absolute top-0 left-0 right-0 bottom-0 object-cover h-full w-full"
				alt={""}
			/>
			<div className="main-overlay absolute z-10 top-0 left-0 right-0 bottom-0 w-full h-full"></div>
			<div className="flex md:flex-row justify-center md:justify-start flex-col z-20">
				<div className="md:flex hidden flex-col justify-end h-full px-4 py-8">
					<Socials />
				</div>

				<div className="flex w-full md:w-1/2 h-auto md:h-full flex-col p-5 md:p-9 h-full justify-center">
					<img
						src={require("./assets/miklogo.png")}
						alt="mik-logo"
						className="w-40"
					/>
					<h1 className="text-4xl text-slate-300 font-bold mt-14">
						Miku juaj i Aventurave
					</h1>
					<p className="text-slate-300 mt-4">
						Filloni në aventurat epike me shoqëruesin tuaj të
						besuar: Miku juaj i aventurave! Zbuloni destinacione
						spektakolare, krijoni kujtime të paharrueshme dhe zhysni
						veten në kultura të ndryshme. Le të fillojë udhëtimi -
						aventura juaj e ardhshme ju pret!!
					</p>
					<a
						href="mailto:mikadventures@gmail.com"
						className="text-slate-300 mb-2 mt-8"
					>
						adventuresmik@gmail.com
					</a>
					<a href="tel:0038344363014" className="text-slate-300 mb-2">
						+383 44 363 014
					</a>
					<a href="tel:003552318155" className="text-slate-300 mb-2">
						+355 69 231 81 55
					</a>
					<br />
					<div className="md:hidden flex h-content flex-row">
						<Socials />
					</div>
				</div>
			</div>
		</div>
	);
}

export default App;
